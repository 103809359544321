<template>
    <m-loading id="StartGame" :loading="gameLoading" :text="loadingText" :key="this.gameInfo.contentId || Date.now()">
        <Video v-if="gameInfo.contentType == 4" :game-info="gameInfo"
               @startPlay="startPlay" @endPlay="endPlay"/>
        <Game v-else :game-info="gameInfo"
              @loaded="startTime = Date.now()" @proceed="finish"/>
    </m-loading>
</template>

<script>
import Apis from "@/requests/api";
import Game from "./components/Game";
import Video from "./components/Video";

export default {
    name: "StartGame",
    components: {Game, Video},
    data() {
        return {
            gameLoading: false,
            submitData: false,
            loadingText: "数据提交中...",
            startTime: Date.now(),
            gameList: [],
            gameInfo: {},
        }
    },
    mounted() {
        this.$store.commit("user/setToken", this.$route.query.token);
        this.getGameList();
    },
    methods: {
        getGameList() {
            this.gameLoading = true;
            this.loadingText = "";
            this.$http.get(Apis.Kindergarten.getGameList, {userCourseId: this.$route.query.userCourseId}).then(res => {
                this.gameLoading = false;
                this.loadingText = "数据提交中...";
                if (res.status) {
                    this.gameList = res.data;
                    this.gameInfo = res.data.filter(f => !f.isFinished)[0] || {};
                    if (this.gameInfo.contentName) {
                        setTimeout(() => {
                            document.title = this.gameInfo.contentName
                        }, 100)
                    }
                } else {
                    this.$toast(res.message);
                }
            })
        },
        finish(type) {
            const list = this.gameList.map(m => {
                if (m.contentId === this.gameInfo.contentId) {
                    m.isFinished = true;
                }
                return m;
            })
            this.gameList = list;
            this.gameInfo = list.filter(f => !f.isFinished)[0] || {};
            if (this.gameInfo.contentName) {
                setTimeout(() => {
                    document.title = this.gameInfo.contentName
                }, 1000)
            }
            if (!this.gameInfo.contentType && !type) {
                window.wx.miniProgram.getEnv(function (res) {
                    if (res.miniprogram) {
                        window.wx.miniProgram.navigateBack({
                            delta: 1
                        })
                    }
                })
            }
        },
        /**
         * 视频播放结束
         * @param data
         */
        endPlay(data) {
            this.gameLoading = true;
            const params = {
                contentSnapshotId: this.gameInfo.contentSnapshotId,
                ...data
            };
            this.$http.post(Apis.Game.updateCompletionTimes, params).then(res => {
                if (!res.status) {
                    this.$toast("数据提交失败");
                } else {
                    this.finish(1);
                }
                this.gameLoading = false;
            });
        },

        /**
         * 视频初始化完成
         * @param player
         */
        startPlay(player) {
            player.play();
        }
    }
}
</script>

<style lang="less" scoped>
#StartGame {
    width: 100%;
    height: 100%;
}
</style>